<template>
  <div class="base">
    <div id="three"></div>
    <div class="main">
      <input type="email" v-model="mailad" placeholder="メールアドレスを入力してください" />
      <p class="little">MAILADDRESS</p>
      <input type="password" v-model="pass" placeholder="パスワードを入力してください" />
      <p class="little">PASSWORD</p>
      <p class="error">{{ message }}</p>
      <div class="button">
        <div>
          <router-link to="/subscribe" class="subscribe">新規登録はこちら</router-link>
        </div>
        <div @click="login()" class="login">
          <p>ログイン</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

export default {
  name: 'Login',
  data () {
    return {
      mailad: '',
      pass: '',
      width: window.innerWidth,
      height: window.innerHeight,
      message: ''
    }
  },
  methods: {
    init () {
      const loader = new GLTFLoader()
      this.scene = new THREE.Scene()
      this.renderer = new THREE.WebGLRenderer()
      this.renderer.gammaOutput = true
      this.renderer.gammaFactor = 2.2
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.width, this.height)
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.width / this.height * 1.2,
        1,
        1000
      )
      this.camera.fov = 60
      this.camera.position.set(50, 100, 1000)
      // 図形を作成
      const me = this
      loader.load('http://localhost:8080/gltf/civic.glb', (gltf) => {
        const model = gltf.scene
        model.scale.set(200, 200, 200)
        model.position.set(0, 0, 0)
        me.scene.add(model)
      }, function (xhr) {
        console.log(xhr)
      }, function (error) {
        console.error(error)
      })
      // ライト
      const hemisphereLight = new THREE.AmbientLight(0xFFFFFF, 10.0)
      this.scene.add(hemisphereLight)
      // 初回実行
      this.tick()
      var canvasWrap = document.getElementById('three')
      this.renderer.setSize(this.width, this.height)
      canvasWrap.appendChild(this.renderer.domElement)
      this.renderer.render(this.scene, this.camera)
    },
    tick () {
      requestAnimationFrame(this.tick)
      this.renderer.render(this.scene, this.camera)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>
.main {
  background-color: rgba(170, 170, 170, 0.397);
  padding: 10px;
}

.error {
  color: red;
  font-size: 14px;
}

#three {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.main input {
  display: block;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid black;
}

.main input::placeholder {
  color: white;
}

.button {
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  align-items: center;
  margin-top: 20px;
}

.login p{
  margin: 0;
}

.subscribe {
  color: black;
  text-decoration: none;
  font-size: 14px;
  border-bottom: 1px solid black;
}

.login {
  border: 1px solid black;
  color: rgb(40, 40, 40);
  cursor: pointer;
  padding: 5px 20px;
  letter-spacing: 3px;
}

.little {
  font-size: 12px;
  margin: 0;
  letter-spacing: 3px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 950px) {
  .login {
    padding: 2px 10px;
    width: 130px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .main {
    width: 300px;
    margin: 250px auto;
  }

  .main input {
    width: 250px;
  }

  .little {
    width: 250px;
  }

  .button {
    width: 300px;
  }
}

@media screen and (min-width: 751px) {
  .main {
    width: 500px;
    margin: 200px auto;
  }

  .main input {
    width: 350px;
  }

  .little {
    width: 350px;
  }

  .button {
    width: 350px;
    display: flex;
  }
}
</style>
